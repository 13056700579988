import React from 'react';

const GetSmart = () => {
  return (
    <div className="get-smart">
      <h4 className="fs-21 ganen-green">
        Get a smart home solution customised{' '}
        <br className="d-none d-sm-block" />
        and professionally installed according to your needs
      </h4>
    </div>
  );
};

export default GetSmart;
