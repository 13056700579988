import React from 'react';

const SubDescription = ({ text1, text2, text3, text4 }) => {
  return (
    <>
      <h4 className="sd-title fs-24 ganen-green ganen-bold">{text1}</h4>
      <div className="py-2">{text2}</div>
      <div className="py-2 mid-text-bordered">{text3}</div>
      <div className="py-2">{text4}</div>
    </>
  );
};

export default SubDescription;
