import React from 'react';

const SystemTailoredCarouselItem = ({
  id,
  backgroundImage = 'BG',
  smallBackgroundImage = 'SmallBG',
}) => {
  return (
    <>
      <div
        className={`item-stc-${id}`}
        style={{ height: '470px', backgroundColor: '#fff' }}
      ></div>

      <style jsx="true">{`
        @media screen and (max-width: 600px) {
          .item-stc-${id} {
            background-image: url(${smallBackgroundImage.srcWebp});
          }
        }

        @media screen and (min-width: 601px) {
          .item-stc-${id} {
            background-image: url(${backgroundImage.srcWebp});
          }
        }

        .item-stc-${id} {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          opacity: 0.99;
        }
      `}</style>
    </>
  );
};

export default SystemTailoredCarouselItem;
