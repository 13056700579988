import React from 'react';

import MerryGoRound from '../../../components/widgets/merry-go-round';

import { useStaticQuery, graphql } from 'gatsby';

import SystemTailoredCarouselItem from './system-tailored-carousel-item';

const SystemTailoredCarousel = () => {
  const {
    phone,
    camera,
    office,
    smallPhone,
    smallCamera,
    smallOffice,
  } = useStaticQuery(graphql`
    query {
      phone: file(relativePath: { eq: "c1-hand-on-phone.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 395, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallPhone: file(relativePath: { eq: "c1-hand-on-phone.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 395, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      camera: file(relativePath: { eq: "c1-camera.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 395, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallCamera: file(relativePath: { eq: "c1-camera.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 395, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      office: file(relativePath: { eq: "c1-office.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 395, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallOffice: file(relativePath: { eq: "c1-office.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 395, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const items = [
    {
      key: 'index-1',
      content: (
        <SystemTailoredCarouselItem
          id="phone"
          backgroundImage={phone.childImageSharp.fluid}
          smallBackgroundImage={smallPhone.childImageSharp.fluid}
        />
      ),
    },
    {
      key: 'index-2',
      content: (
        <SystemTailoredCarouselItem
          id="camera"
          backgroundImage={camera.childImageSharp.fluid}
          smallBackgroundImage={smallCamera.childImageSharp.fluid}
        />
      ),
    },
    {
      key: 'index-3',
      content: (
        <SystemTailoredCarouselItem
          id="office"
          backgroundImage={office.childImageSharp.fluid}
          smallBackgroundImage={smallOffice.childImageSharp.fluid}
        />
      ),
    },
  ];

  return (
    <MerryGoRound
      items={items}
      fixHeight={400}
      interval={3000}
      ride="carousel"
      autoPlay={true}
    />
  );
};

export default SystemTailoredCarousel;
