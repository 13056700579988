import React from 'react';

import SystemTailoredCarousel from './system-tailored-carousel';
import SystemTailoredInfo from './system-tailored-info';
import SubDescriptions from './system-tailored-subs';

const SystemTailored = () => {
  return (
    <section>
      <div className="box-container row pb-4 system-tailored">
        <div className="col-12 col-sm-6 col-lg-5 pl-5 pt-4 pt-sm-5">
          <SystemTailoredCarousel />
        </div>

        <div className="col-12 col-sm-6 col-lg-7 right-content pl-5 pt-4 pt-sm-5">
          <SystemTailoredInfo />
        </div>

        <div className="d-block d-lg-none col-12 pl-5 mt-0 mt-sm-4">
          <div className="col-12 row p-0 m-0 pt-4">
            <SubDescriptions />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SystemTailored;
