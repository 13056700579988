import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import WhoWeAreForItem from './who-we-are-for-item';

const WhoWeAreFor = () => {
  const {
    girls,
    officeworkers,
    officewindow,
    office,
    boys,
    smallGirls,
    smallOfficeworkers,
    smallOfficewindow,
    smallOffice,
    smallBoys,
  } = useStaticQuery(graphql`
    query {
      girls: file(relativePath: { eq: "wwaf-girls.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallGirls: file(relativePath: { eq: "wwaf-girls.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      officeworkers: file(relativePath: { eq: "wwaf-office-workers.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallOfficeworkers: file(
        relativePath: { eq: "wwaf-office-workers.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      officewindow: file(relativePath: { eq: "wwaf-office-window.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallOfficewindow: file(relativePath: { eq: "wwaf-office-window.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      office: file(relativePath: { eq: "wwaf-office.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallOffice: file(relativePath: { eq: "wwaf-office.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      boys: file(relativePath: { eq: "wwaf-boys.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallBoys: file(relativePath: { eq: "wwaf-boys.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="my-5 wwaf">
      <div className="text-center fs-42 ganen-bold ganen-green  mb-3 mb-md-5">
        Who We Are For
      </div>

      <WhoWeAreForItem
        id="girls"
        backgroundImage={girls.childImageSharp.fluid}
        smallBackgroundImage={smallGirls.childImageSharp.fluid}
        title="Homes"
        description="Every owner deserves{br}control of their own space"
        buttonLabel="Job References"
        buttonLink="home-job-references"
      />

      <WhoWeAreForItem
        id="officeworkers"
        backgroundImage={officeworkers.childImageSharp.fluid}
        smallBackgroundImage={smallOfficeworkers.childImageSharp.fluid}
        title="Offices"
        description="Let us build that affordable solution for you!{br}Commercial | Industrial | Retail"
        buttonLabel="Job References"
        buttonLink="office-job-references"
        onLeft={false}
      />

      <WhoWeAreForItem
        id="officewindow"
        backgroundImage={officewindow.childImageSharp.fluid}
        smallBackgroundImage={smallOfficewindow.childImageSharp.fluid}
        title="Hoteliers"
        description="Fortify your hotel with streamlined{br}automation & lighting solutions"
        buttonLabel="Find out more"
        buttonLink="hoteliers-and-property-developers"
      />

      <WhoWeAreForItem
        id="office"
        backgroundImage={office.childImageSharp.fluid}
        smallBackgroundImage={smallOffice.childImageSharp.fluid}
        title="Property Developers"
        description="Value add to your product and sell a smart home{br}that all your customers deserve"
        buttonLabel="Find out more"
        buttonLink="hoteliers-and-property-developers"
        onLeft={false}
      />

      <WhoWeAreForItem
        id="boys"
        backgroundImage={boys.childImageSharp.fluid}
        smallBackgroundImage={smallBoys.childImageSharp.fluid}
        title="Distributors"
        description="Contact us today and we can work towards a{br}shared business vision and partnership strategies!"
        buttonLabel="Contact Us"
        buttonLink="contact-us"
      />
    </div>
  );
};

export default WhoWeAreFor;
