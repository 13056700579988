import React from 'react';

import HomeBanner from '../components/content/home/home-banner';
import GetSmart from '../components/content/home/get-smart';
import SystemTailored from '../components/content/home/system-tailored';
import ContactUs from '../components/widgets/contact-us';
import WhoWeAreFor from '../components/content/home/who-we-are-for';

import Layout from '../components/layout/main';
import Head from '../components/layout/head';

const IndexPage = () => (
  <Layout>
    <Head title={'Home'} />

    <HomeBanner />

    <GetSmart />

    <SystemTailored />

    <ContactUs sectionClass="bg-ganen-gray-3" />

    <WhoWeAreFor />
  </Layout>
);

export default IndexPage;
