import React from 'react';

import SubDescription from './system-tailored-sub';

const SubDescriptions = () => {
  return (
    <>
      <div className="col-12 col-sm-4 pl-0">
        <SubDescription
          text1="Home automation"
          text2="Fully customisable"
          text3="In control, anywhere"
          text4="Google Home integration"
        />
      </div>

      <div className="col-12 col-sm-4 pl-0">
        <SubDescription
          text1="Home security"
          text2="Smart alerts"
          text3="24/7 protection"
          text4="Real-time back up"
        />
      </div>

      <div className="col-12 col-sm-4 pl-0">
        <SubDescription
          text1="Home lighting"
          text2="Wireless control"
          text3="Unlimited control"
          text4="Batteryless switches"
        />
      </div>
    </>
  );
};

export default SubDescriptions;
