import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import CallSVG from '../../../images/call.svg';

const HomeBanner = () => {
  const { site, bedroom, smallBedroom } = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          contactNumber
        }
      }
      smallBedroom: file(relativePath: { eq: "bedroom.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bedroom: file(relativePath: { eq: "bedroom.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <section>
      <div className="home-banner">
        <div className="box-container">
          <div className="banner-content">
            <h1 className="fs-60 ganen-green ganen-bold">Your Home 2.0</h1>

            <div className="pb-3 pb-md-5">
              Work together with your interior designers and our GANEN
              Specialists to create your ideal smart home customised according
              to your needs. That house you dreamt of is just a call away.
            </div>

            <a
              className="call-button-link fs-20 btn bg-ganen-brown rounded-10 text-white px-3 px-md-5"
              href={`tel:${site.siteMetadata.contactNumber}`}
            >
              <CallSVG className="hw-20 pr-t-3" />{' '}
              {`${site.siteMetadata.contactNumber}`}
            </a>
          </div>
        </div>
      </div>

      <style jsx="true">{`
        @media screen and (max-width: 600px) {
          .home-banner {
            background-image: url(${smallBedroom.childImageSharp.fluid
              .srcWebp});
          }
        }

        @media screen and (min-width: 601px) {
          .home-banner {
            background-image: url(${bedroom.childImageSharp.fluid.srcWebp});
          }
        }

        .home-banner {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          opacity: 0.99;
        }
      `}</style>
    </section>
  );
};

export default HomeBanner;
