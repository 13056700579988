import React, { Fragment } from 'react';

import { Link } from 'gatsby';

const WhoWeAreForItem = ({
  id,
  title,
  description,
  buttonLabel,
  buttonLink,
  backgroundImage = 'BG',
  smallBackgroundImage = 'SmallBG',
  onLeft = true,
}) => {
  const implementLineBreak = (text) => {
    const texts = text.split('{br}');

    if (texts.length <= 1) {
      return text;
    }

    const updatedText = [];
    texts.forEach((separateText, key) => {
      updatedText.push(
        <Fragment key={`s-text-${id}-${key}`}>
          {separateText}
          <br />
        </Fragment>
      );
    });

    return updatedText;
  };

  return (
    <>
      <div className={`row wwaf-item item-${id}`}>
        {onLeft ? '' : <div className="d-none d-md-block col-6"></div>}

        <div className={`col-12 col-md-6 sbg ${onLeft ? 'text-right' : ''}`}>
          <h3 className="fs-35 ganen-green ganen-bold">{title}</h3>

          <div className="desc">{implementLineBreak(description, id)}</div>

          <Link
            className="btn bg-ganen-brown rounded-5 text-white px-4"
            to={`${buttonLink}`}
          >
            {buttonLabel}
          </Link>
        </div>

        {onLeft ? <div className="d-none d-md-block col-6"></div> : ''}
      </div>

      <style jsx="true">{`
        @media screen and (max-width: 600px) {
          .item-${id} {
            background-image: url(${smallBackgroundImage.srcWebp});
          }

          .item-${id} {
            background-attachment: unset;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        @media screen and (min-width: 601px) {
          .item-${id} {
            background-image: url(${backgroundImage.srcWebp});
          }
        }

        .item-${id} {
          background-attachment: fixed;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          opacity: 0.99;
        }
      `}</style>
    </>
  );
};

export default WhoWeAreForItem;
