import React from 'react';

import SubDescriptions from './system-tailored-subs';

const SystemTailoredInfo = () => {
  return (
    <>
      <h2 className="fs-42 ganen-green ganen-bold">A system tailored to you</h2>

      <div className="right-content-desc">
        Every GANEN solution is customised to fit your lifestyle in a way that
        works for you and your budget. And whether you want just the basics or
        all the bells and whistles, GANEN can work with you to create the
        perfect home for your comfort and convenience.
      </div>

      <div className="d-none d-lg-block">
        <div className="d-flex right-content-desc-2 row">
          <SubDescriptions />
        </div>
      </div>
    </>
  );
};

export default SystemTailoredInfo;
